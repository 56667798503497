import { render, staticRenderFns } from "./ItemDropdown.vue?vue&type=template&id=ea89dce6&scoped=true&"
import script from "./ItemDropdown.vue?vue&type=script&lang=ts&"
export * from "./ItemDropdown.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea89dce6",
  null
  
)

export default component.exports